import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./content_page.css";
import projectsData from "../components/project.json";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Footer1 from "./Footer1";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../Utilities/axios";
import toast from "react-hot-toast";

const proj = projectsData.projects;

export default function Signup_site() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const handleSubmit = () => {
  //   localStorage.setItem('SubToken','sdas')
  //   setShow(false);
  //   history.push("/RESPA/Program");
  // };

  const subscribeSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const history = useHistory();
  const routeChange = (element) => {
    history.push(element);
  };

  const formikSubscribe = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: subscribeSchema,
    onSubmit: async (values) => {
      setLoader(true);
      try {
        const formData = new FormData();
        formData.append("email", values?.email);
        formData.append("has_free_trial", 1);
        const response = await axiosInstance.post(
          "/permanent/trial/post",
          formData
        );
        console.log(response?.data, "subscribe---->");
        if (response?.data?.status) {
          setLoader(false);
          localStorage.setItem("SubToken", response?.data?.token);
          localStorage.setItem("SubEmail", values?.email);
          toast.success(response?.data?.message, { duration: 2000 });
          history.push("/RESPA/Program");
        }
      } catch (e) {
        setLoader(false);
        console.log(e);
        toast.error(e?.response);
      }
    },
  });

  return (
    //   <section className="color-section" id="title">
    //     <div className="container-fluid">
    //     <section className="black-section" id="pricing-black">

    //     <h2 className="section-heading">NMLS Mortgage Licence Prep</h2>
    //     <img className="image" src={require('../components/images/APE_LOGO.jpg') }/>
    //     <p>Introducing the ultimate education destination for aspiring Loan Officers. As the newest powerhouse in adult education, our program is equipped with the most advanced learning strategies and the fastest training methodologies available. Our courses are meticulously designed to ensure you not only learn faster but also retain information longer, setting you up to pass your NMLS exams on the first attempt. Join the vanguard of professionals who are choosing our top-tier, flexible online MLO Prep. Step into a future where you lead as a top-tier Loan Officer.</p>
    //     { !userData &&     <button type="button" className="button is-link is-medium mt-4" onClick={(nextClickHandler) =>routeChange("/subscription")} >Sign Up $295</button>}
    //     </section>
    //     </div>

    //     <div className="container-fluid">
    //      <section className="white-section" >

    //     <h2 className="section-heading">NMLS Masterclass Prep Course</h2>
    //     <p>We give you everything you need upfront—no need for multiple programs or later upgrades. Get all you need now to pass your NMLS exam. As a current realtor and loan officer who has faced the same challenges you're encountering now, I know firsthand the gaps in available training materials. That's why I established Apex Prime Education LLC—to create the ultimate one-stop shop for passing your exam swiftly and starting your career as soon as possible. We don’t just prepare you to pass—we ensure you truly understand and retain what you learn. Our program, developed from thousands of hours of dedicated effort over four years, integrates proven learning strategies tailored to different learning styles and paces. Don’t settle for less. Choose Apex Prime Education LLC and accelerate your path to a successful career. </p>
    //     {/* <break></break> */}
    //     <p className="list">
    //     <em><b>Complete Courses at Your Own Pace:</b> Progress through the curriculum on your schedule, adapting your learning to fit your life.</em></p>
    //     <p className="list"><em><b>Study When and Where You Want:</b> Access our courses anywhere, anytime—perfect for on-the-go or at-home learning.</em></p>
    //     <p className="list"><em><b>Comprehensive Video Lessons:</b> Engage with dynamic video content that makes complex concepts easy to understand and remember.</em></p>
    //     <p className="list"><em><b>In-depth Study Guides:</b> Utilize our detailed guides to deepen your knowledge and enhance your study sessions.</em></p>
    //     <p className="list"><em><b>Interactive Audio Materials:</b> Learn through audio formats ideal for commuting or whenever you prefer listening over reading.</em></p>
    //     <p className="list"><em><b>Helpful Flash Cards:</b> Quickly review key terms and concepts with easy-to-use flash cards.</em></p>
    //     <p className="list"><em><b>Extensive Vocabulary Lists:</b> Master the critical terminology needed to succeed in your NMLS exams.</em></p>
    //     <p className="list"><em><b>Printable Learning Modules:</b> Dive deeper into complex topics with print-outs that help you better understand and retain information more efficiently.</em></p>
    //     <p className="list"><em><b>Assessment Tools (Quizzes, Exams, Tests):</b> Reinforce your learning through targeted quizzes, practice tests, and comprehensive exams to guide your study through each area.</em></p>
    //     <p className="list"><em><b>Customizable Study Guide Builder:</b> After your exams, easily navigate to specific areas you need to review using our innovative study guide builder—no more wasting time searching through extensive material for the information you missed.</em></p>
    // { !userData &&     <button type="button" className="button is-link is-medium mt-4" onClick={(nextClickHandler) =>routeChange("/subscription")} >Sign Up $295</button>}

    //     <div className="spacing">
    //     </div>

    //   </section>
    //     </div>
    // </section>
    <>
      <section className="color-section" id="title">
        <div className="container-fluid">
          {/* <section class="black-section pb-5" id="pricing-black">
            <h2 className="section-heading ">Apex Prime Education LLC</h2>
            <h2 className="section-heading pb-5">NMLS Mortgage Licence Prep</h2>
            <img
              className="image"
              src={require("../components/images/APE_LOGO.jpg")}
            />
            <p className="pt-5">
              Introducing the ultimate education destination for aspiring Loan
              Officers. As the newest powerhouse in adult education, our program
              is equipped with the most advanced learning strategies and the
              fastest training methodologies available. Our courses are
              meticulously designed to ensure you not only learn faster but also
              retain information longer, setting you up to pass your NMLS exams
              on the first attempt. Join the vanguard of professionals who are
              choosing our top-tier, flexible online MLO Prep. Step into a
              future where you lead as a top-tier Loan Officer.
            </p>
            {!userData && (
              <button
                type="button"
                className="button is-link is-medium mt-4"
                onClick={(nextClickHandler) => routeChange("/plan")}
              >
                Sign Up
              </button>
            )}
          </section> */}

          
          <div id="pricing-black" className="mb-5">
            <h2 className="section-heading pt-3">Apex Prime Education LLC</h2>
            <h2 className="section-heading pb-5">NMLS Mortgage Licence Prep</h2>
         <div className="row">
          <div className="col-lg-1 col-md-0 col-xs-0">
          </div>
         <div className="col-lg-3 col-md-5 col-xs-12">
          <img
              className="image"
              width={300}
              height={300}
              src={require("../components/images/APE_LOGO.jpg")}
            />
          </div>
           <div className="col-lg-7 col-md-7 col-xs-12">
           <p className="pt-5 text-start">
              Introducing the ultimate education destination for aspiring Loan
              Officers. As the newest powerhouse in adult education, our program
              is equipped with the most advanced learning strategies and the
              fastest training methodologies available. Our courses are
              meticulously designed to ensure you not only learn faster but also
              retain information longer, setting you up to pass your NMLS exams
              on the first attempt. Join the vanguard of professionals who are
              choosing our top-tier, flexible online MLO Prep. Step into a
              future where you lead as a top-tier Loan Officer.
            </p>
            {!userData && (
              <button
                type="button"
                className="button is-link is-medium mt-4"
                onClick={(nextClickHandler) => routeChange("/plan")}
              >
                Sign Up
              </button>
            )}
           </div>
           <div className="col-lg-1 col-md-0 col-xs-0">
           </div>
         </div>
          </div>
        
        <h4 className="section-heading pt-5">Try Our Free Sample Program Today!</h4>
       <div>
       <img
              // className="image"
              style={{height:'350px'}}
              src={require("../components/images/freeSample.jpg")}
            />
       </div>
        <button
          type="button"
          className="button is-link is-medium mt-4"
          onClick={handleShow}
        >
          Free Sample Program
        </button>
        </div>
        <div className="container-fluid pt-1">
          <section className="white-section">
            <h2 className="section-heading">NMLS Masterclass Prep Course</h2>

            <p className="py-3">
              Pass your NMLS exam on the first try with{" "}
              <b>everything you need in one place. </b>This course was created
              by a <b>licensed real estate and home loan broker </b>who
              understands the challenges of exam preparation and knows how to
              overcome them.
            </p>

            <break></break>
            <h6 className="section-heading text-start fs-5 pt-4 pb-2">
              Why Choose Us?
            </h6>
            <div className="pl-5">
              <p class="list">
                <em>
                  <b>1,000+ Exam-Style Questions: </b> Practice with realistic
                  questions to ensure you’re ready.
                </em>
              </p>
              <p class="list">
                <em>
                  <b>Engaging Video Lessons: </b>Simplify complex concepts with
                  hours of dynamic, easy-to-follow content.
                </em>
              </p>
              <p class="list">
                <em>
                  <b>Interactive Flashcards: </b> Quickly review and master key
                  terms and concepts.
                </em>
              </p>
              <p class="list">
                <em>
                  <b>Customizable Study Tools: </b> Focus on what matters most
                  with our study guide, learning module printouts, and coding
                  system that helps you quickly access exactly what you need
                  <b>
                    —stop wasting time and money searching through endless
                    materials.
                  </b>
                </em>
              </p>
              <p class="list">
                <em>
                  <b>Quizzes and Practice Exams: </b> Reinforce your knowledge
                  and track your progress every step of the way.
                </em>
              </p>
            </div>

            <h6 className="section-heading text-start fs-5 pt-4 pb-2">
              Flexible and Effective Learning
            </h6>
            <div className="pl-5 list-div">
              <p class="list">Study at your own pace, anytime, anywhere.</p>
              <p class="list">
                Access materials in video, audio, printable formats, and
                flashcards to suit your learning style.
              </p>
            </div>

            <p className="py-3">
              Developed over <b>4+ years</b>,this program is designed to do more
              than help you pass—it ensures you retain what you learn and are
              fully prepared for your career. Don’t wait—<b>enroll today </b>and
              take the first step toward a successful future.
            </p>

            {!userData && (
              <button
                type="button"
                className="button is-link is-medium mt-4"
                onClick={(nextClickHandler) => routeChange("/plan")}
              >
                Sign Up
              </button>
            )}

            <div className="spacing"></div>
          </section>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Subscribe Over Platform</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="Auth-form py-4"
            onSubmit={formikSubscribe.handleSubmit}
          >
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="name@example.com"
                value={formikSubscribe.values.email}
                onChange={formikSubscribe.handleChange}
                onBlur={formikSubscribe.handleBlur}
                isInvalid={
                  formikSubscribe.touched.email &&
                  !!formikSubscribe.errors.email
                }
              />
              <Form.Control.Feedback type="invalid">
                {formikSubscribe.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loader}>
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={formikSubscribe.handleSubmit}
            disabled={loader}
          >
            {loader ? "Submitting..." : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer1 />
    </>
  );
}
